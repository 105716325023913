// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-dato-cms-blog-post-slug-tsx": () => import("./../../../src/pages/blog/{DatoCmsBlogPost.slug}.tsx" /* webpackChunkName: "component---src-pages-blog-dato-cms-blog-post-slug-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-changelog-dato-cms-release-note-slug-tsx": () => import("./../../../src/pages/changelog/{DatoCmsReleaseNote.slug}.tsx" /* webpackChunkName: "component---src-pages-changelog-dato-cms-release-note-slug-tsx" */),
  "component---src-pages-changelog-tsx": () => import("./../../../src/pages/changelog.tsx" /* webpackChunkName: "component---src-pages-changelog-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pages-clients-tsx": () => import("./../../../src/pages/pages/clients.tsx" /* webpackChunkName: "component---src-pages-pages-clients-tsx" */),
  "component---src-pages-pages-how-it-works-js": () => import("./../../../src/pages/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-pages-how-it-works-js" */),
  "component---src-pages-pages-how-to-use-js": () => import("./../../../src/pages/pages/how-to-use.js" /* webpackChunkName: "component---src-pages-pages-how-to-use-js" */),
  "component---src-pages-pages-integrations-js": () => import("./../../../src/pages/pages/integrations.js" /* webpackChunkName: "component---src-pages-pages-integrations-js" */),
  "component---src-pages-pages-pricing-tsx": () => import("./../../../src/pages/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pages-pricing-tsx" */),
  "component---src-pages-pages-resources-tsx": () => import("./../../../src/pages/pages/resources.tsx" /* webpackChunkName: "component---src-pages-pages-resources-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */)
}

